<template>
  <div class="main">
    <HeaderTitle></HeaderTitle>
    <div class="bgc"></div>
    <div class="center c">
      <div class="centerLeft">
        <div class="title">
          <span></span>
          <span>招聘会信息</span>
          <el-divider></el-divider>
        </div>
        <div class="content">
          <div class="contentTitle">
            <p>{{ jobFair.title }}</p>
            <div class="jobFair-intro">
              {{ jobFair.intro }}
            </div>
          </div>
          <div class="time-bt">
          <div class="time-btn">
            <div class="left">
              <div class="leftRegisTime">
                报名时间 : <span>{{ moment(jobFair.beginApplyTime).format('YYYY-MM-DD HH:mm') }}</span>
              </div>
              <div class="leftWreTime">
                举办时间 : <span>{{ moment(jobFair.beginTime).format('YYYY-MM-DD HH:mm') }}</span>
              </div>
            </div>
            <!-- @click="sginUpJobFair" -->
            <div class="right">
              <el-button
                size="medium"

                :disabled="disabled"
                type="primary"
                v-if="held"
                >已举办</el-button
              >
              <el-button
                size="medium"
                :disabled="disabled"
                type="primary"
                v-else
                >{{ this.isSignUp ? "已报名" : "立即报名" }}</el-button
              >
            </div>
          </div>
          </div>

          <div class="math-info">
            <el-row>
              <el-col :span="8">
                <div class="grid-content">
                  <div class="span">{{ subNum1 }}</div>
                  <div class="solid"></div>
                  <div class="math-title">参展单位</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content">
                  <div class="span">{{ subNum2 }}</div>
                  <div class="solid"></div>
                  <div class="math-title">可投递职位</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content">
                  <div class="span">{{ subNum3 }}</div>
                  <div class="solid"></div>
                  <div class="math-title">报名人数</div>
                </div>
              </el-col>
            </el-row>
            <div class="math-bottom">
              主办单位: <span>{{ jobFair.sponsor }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="jobFairWork c">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="参展单位" name="first">
          <div class="work-content">
            <div
              class="companyWork"
              v-for="(item, index) in applyOrgList"
              :key="index"
            >
              <div class="head" @click.stop="toDatumOrg(item)">
                <div class="head-img-box">
                  <img
                    v-if="item.logo && item.logo != 'null'"
                    :src="$imgUrl(item.logo)"
                    alt=""
                    :onerror="$global.srcImgUrl"
                    class="head-img"
                  />
                  <div class="fLogo" v-else>
                    <span>{{ getFirstWord(item.name) }}</span>
                  </div>
                </div>
                <div class="head-right">
                  <div class="work-name">{{ item.name }}</div>
                  <div class="work-info">
                    {{ getJobOrgType(item.properties) }} ·
                    {{ getJobGm(item.size) }} · {{ getOrgType(item.orgType) }}
                  </div>
                </div>
              </div>
              <div class="nav">
                <div
                  class="nav-info"
                  v-if="item.postList.length <= 3 && item.postList.length > 0"
                >
                  <div
                    class="nav-list"
                    v-for="(info, idx) in item.postList"
                    :key="idx"
                  >
                    <div class="left">
                      <span class="left-text">{{ info.name }}</span>
                      <span class="right-text">{{
                        info.pay ? getPay(info.pay) : ""
                      }}</span>
                    </div>
                    <div class="right">
                      {{ info.cityName }} <span>|</span>
                      {{ getPostEdu(info.edu) }} <span>|</span>
                      {{ getPostExp(info.exp) }}
                    </div>
                  </div>
                </div>
                <div class="nav-info" v-else-if="item.postList.length > 3">
                  <div
                    class="nav-list"
                    v-for="(info, idx) in item.postList.slice(0, 3)"
                    :key="idx"
                  >
                    <div class="left">
                      <span class="left-text">{{ info.name }}</span>
                      <span class="right-text">{{
                        info.pay ? getPay(info.pay) : ""
                      }}</span>
                    </div>
                    <div class="right">
                      {{ info.cityName }} <span>|</span>
                      {{ getPostEdu(info.edu) }} <span>|</span>
                      {{ getPostExp(info.exp) }}
                    </div>
                  </div>
                </div>
                <div class="more" v-if="item.postList.length > 3">
                  更多职位>>
                </div>
              </div>
              <div class="btn">
                <el-button
                  class="square-btn"
                  type="primary"
                  icon="el-icon-chat-dot-square"
                  @click="sendMessage(item)"
                  round
                  >立即沟通</el-button
                >
              </div>
            </div>
          </div>
          <el-pagination
            v-if="applyOrgList.length > 0"
            background
            layout="prev, pager, next"
            :total="totalRows1"
            :current-page="totalList1"
            :page-size="12"
            @current-change="chenPagination1"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="招聘职位" name="second">
          <div class="business-list">
            <div
              class="business"
              v-for="(item, index) in applyPostList"
              :key="index"
              @click.stop="toDatumInfo(item)"
            >
              <div class="head">
                <div class="right-info">
                  <div class="res-title">
                    <div class="res-name">{{ item.jobPost.name }}</div>
                    <div class="pay">
                      {{ item.jobPost.pay ? getPay(item.jobPost.pay) : "" }}
                    </div>
                  </div>
                  <div class="title-info">
                    {{ item.jobPost.cityName }}
                    <span>| </span>
                    {{ getPostExp(item.jobPost.exp) }}
                    <span>|</span>
                    {{ getPostEdu(item.jobPost.edu) }}
                  </div>
                </div>
              </div>
              <div class="label-list">
                <img
                  v-if="item.jobOrg.logo && item.jobOrg.logo != 'null'"
                  :src="$imgUrl(item.jobOrg.logo)"
                  alt=""
                  :onerror="$global.srcImgUrl"
                  class="img"
                />
                <div class="cLogo" v-else>
                  <span>{{ getFirstWord(item.jobOrg.name) }}</span>
                </div>
                <div class="labe-info">
                  <!-- {{ item.orgName }} -->
                  <div>{{ item.jobOrg.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <el-pagination
            v-if="applyPostList.length > 0"
            background
            layout="prev, pager, next"
            :total="totalRows2"
            :current-page="totalList2"
            :page-size="12"
            @current-change="chenPagination2"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>
    <Footer></Footer>
    <el-dialog
      :visible.sync="visibleIM"
      width="1240px"
      top="10vh"
      :before-close="handleClose"
    >
      <Notice v-if="visibleIM"></Notice>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import HeaderTitle from "@/components/hometitle/hometitle.vue";
import Footer from "@/components/footer/footers.vue";
import {
  jobFairDetail,
  getApplyOrgPageForId,
  userApply,
  getjobFairSignUp,
  getJobFairApplyOrg,
  getJobFairPostOrg,
  getjobFairPageByUser,
} from "@/api/jobFair/jobFair";
import { jobPostType } from "@/api/position/position";
import { detailTeam } from "@/api/team/team";
import { getJobUserAuth } from "@/api/jobInformation/jobInformation";
import Notice from "@/components/notice/notice.vue";
export default {
  name: "",
  data() {
    return {
      totalList1: 1,
      totalRows1: 0,
      totalList2: 1,
      totalRows2: 0,
      subNum1: 0,
      subNum2: 0,
      subNum3: 0,
      jobEdu: [],
      jobExp: [],
      visibleIM: false,
      activeName: "first",
      jobUserId: "",
      dict: [],
      jobVipEquity: [],
      jobOrgType: [],
      jobFairId: "",
      jobGM: [],
      jobFair: {}, //招聘会详情
      applyOrgList: [], //参展单位列表
      applyPostList: [], // 职位列表
      tradeType: [], //单位类型
      held: "", //招聘会是否举办
      disabled: false, //按钮禁用
      isSignUp: false, //是否报名
      tips: "",
      userId: "",
      jobPay: [], //薪资
      params1: {
        pageNo: 1,
        pageSize: 12,
      },
      params2: {
        pageNo: 1,
        pageSize: 12,
      },
    };
  },
  watch: {},
  props: {},
  components: {
    HeaderTitle,
    Footer,
    Notice,
  },
  computed: {
    ...mapState(["peopleList"]),
  },
  created() {
    this.jobUserId = localStorage.getItem("userId");
    this.dict = JSON.parse(localStorage.getItem("dict"));
    this.jobOrgType = this.dict.filter(
      (i) => i.code == "job_org_type"
    )[0].children;
    this.jobPay = this.dict.filter((i) => i.code == "job_pay")[0].children;
    this.jobGM = this.dict.filter((i) => i.code == "job_gm")[0].children;
    this.jobEdu = this.dict.filter((i) => i.code == "job_edu")[0].children;
    this.jobExp = this.dict.filter((i) => i.code == "job_exp")[0].children;
    this.jobFairId = this.$route.query.id;
    this.held = this.$route.query.held ? true : false;
    this.disabled = this.held ? true : false;
    this.getJobFairDetail();
    this.getApplyOrgList();
    this.getApplyPostOrg();
    this.getJobTradeType();
    this._getjobFairSignUp();
  },
  mounted() {},
  methods: {
    moment,
    chenPagination1(cur) {
      this.params1.pageNo = cur;
      this.getApplyOrgList();
    },
    chenPagination2(cur) {
      this.params2.pageNo = cur;
      this.getApplyPostOrg();
    },
    getPay(code) {
      return this.jobPay.filter((i) => i.code == code)[0].name;
    },
    handleClick(tab, event) {
      if(tab.name !== 'second'){
        this.params1.pageNo = 1;
         this.getApplyOrgList();
      }else{
        this.params2.pageNo = 1;
        this.getApplyPostOrg();
      }
    },
    getPostEdu(id) {
      return this.jobEdu.filter((i) => i.code == id)[0].name;
    },
    getPostExp(id) {
      return this.jobExp.filter((i) => i.code == id)[0].name;
    },
    // 查看职位详情
    toDatumInfo(item) {
      this.$router.push({
          path: '/datum',
          query: { id: item.jobPost.id },
      })
    },
    toDatumOrg(item) {
      this.$router.push({
        path: "/enterpriseDetails",
        query: { id: item.id },
      })
    },
    // 查询是否报名
    _getjobFairSignUp() {
      getjobFairSignUp({ jobUserId: localStorage.getItem("userId") }).then(
        (res) => {
          let data = res.data.rows;
          this.isSignUp = data.some((i) => i.jobFairId == this.jobFairId);
          if (this.isSignUp) {
            this.disabled = true;
          }
        }
      );
    },
    // 获取行业类型
    getJobTradeType() {
      jobPostType({ code: "trade_type" }).then((res) => {
        this.tradeType = res.data[0].children;
      });
    },
    // 发送消息
    async sendMessage(val) {
      if (!localStorage.getItem("userId")) {
        this.$router.push("/login");
        return;
      }
      let res = await getJobUserAuth();
      let userAuth = res.data;
      let jobUserId = localStorage.getItem("userId");
      let info = await detailTeam({ jobUserId });
      let userTeam = info.data;
      if (userAuth != null || (userTeam != null && userTeam.auditStatus == 2)) {
        this.visibleIM = true;
        if (this.peopleList.find((i) => i.userProfile.userID == val.id)) {
          return;
        }
        let peopleList = this.peopleList;
        let obj = {
          userProfile: {
            userID: val.id,
            nick: val.name,
          },
        };
        this.peopleList.unshift(obj);
        this.$store.commit("set_peopleList", this.peopleList);
      } else {
        this.$message.warning("请认证(个人/班组)后进行沟通");
      }
    },
    handleClose() {
      this.visibleIM = false;
    },

    // 招聘会详情
    getJobFairDetail() {
      jobFairDetail({ id: this.jobFairId }).then((res) => {
        this.jobFair = res.data;
      });
      getjobFairPageByUser({ jobFairId: this.jobFairId }).then((res) => {
        this.subNum3 = res.data.totalRows;
      });
    },
    // 参展单位
    getApplyOrgList() {
      let params = {
        ...this.params1,
        jobFairId: this.jobFairId,
        auditStatus: "2",
      };
      getJobFairApplyOrg(params).then((res) => {
        this.totalRows1 = res.data.totalPage;
        this.subNum1 = res.data.totalRows;
        this.applyOrgList = res.data.rows;
      });
    },
    // 职位列表
    getApplyPostOrg() {
      let params = {
        ...this.params2,
        jobFairId: this.jobFairId,
      };
      getJobFairPostOrg(params).then((res) => {
        this.totalRows2 = res.data.totalPage;
        this.subNum2 = res.data.totalRows;
        this.applyPostList = res.data.rows;
      });
    },
    getOrgType(str) {
      let orgType = str.split(",");
      let arr = [];
      orgType.forEach((i) => {
        arr.push(this.getOrgTypeForId(this.tradeType, i));
      });
      return arr.join("/");
    },
    getFirstWord(name) {
      if (!name) {
        return;
      }
      let pinyin = require("js-pinyin");
      pinyin.setOptions({ checkPolyphone: false, charCase: 0 });
      let word = pinyin.getFullChars(name).substring(0, 1);
      return word;
    },
    getOrgTypeForId(list, id) {
      var title = "";
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          title = list[i].title;
          break;
        } else if (list[i].children && list[i].children.length > 0) {
          title = this.getOrgTypeForId(list[i].children, id);
          if (title) return title;
        }
      }
      return title;
    },
    // 单位类型
    getJobOrgType(code) {
      return this.jobOrgType.filter((i) => i.code == code)[0].name;
    },
    // 单位规模
    getJobGm(code) {
      return this.jobGM.filter((i) => i.code == code)[0].name;
    },
    sginUpJobFair() {
      let userId = localStorage.getItem("userId");
      if (!userId) {
        this.$message.warning("暂未登录，请先前往登录");
        return;
      } else {
        let beginApplyTime = this.jobFair.beginApplyTime;
        // 报名时间在当前时间前面，报名已开始
        let ApplyFlag = moment(beginApplyTime).isBefore(new Date());
        if (ApplyFlag) {
          this._userApply();
        } else {
          this.$message.warning("报名未开始");
        }
      }
    },
    // 报名
    _userApply() {
      let obj = {
        jobFairId: this.jobFair.id,
        jobUserId: localStorage.getItem("userId"),
      };
      userApply(obj).then((res) => {
        if (res.code == 200) {
          this._getjobFairSignUp();
          // this._subtractEquityNum();
          this.$message({
            message: res.message,
            type: "success",
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  filters: {},
};
</script>

<style scoped lang="scss">
.c {
  width: 1200px;
  margin: 0 auto;
}
.jobFair-intro{
  text-indent: 30px;
  box-sizing: border-box;
  padding: 0 60px 20px 60px;
}

.bgc {
  height: 350px;
  z-index: -1;
  background: url("~@/assets/jobFairDetails/bgc.png");
  position: relative;
}
.bgc::after {
  position: absolute;
  content: "";
  width: 790px;
  height: 530px;
  top: 30%;
  left: 30%;
  transform: translate(-50%, -50%);
  background: url("~@/assets/jobFairDetails/text.png");
}

.pIndex {
  text-indent: 4em;
}
.pFont {
  color: #666;
  font-size: 14px;
}

.center {
  display: flex;
  justify-content: space-between;
  margin-top: -70px;
  margin-bottom: 20px;
}

.blueTitle {
  display: flex;
  align-items: center;
  span:nth-child(1) {
    width: 16px;
    height: 16px;
    background-color: #0a96f3;
    margin-right: 10px;
  }
  span:nth-child(2) {
    color: #0a96f3;
  }
}

.centerLeft {
  width: 1200px;
  background-color: #fff;
  padding-top: 30px;
  .title {
    display: flex;
    align-items: center;
    span:nth-child(1) {
      width: 10px;
      height: 30px;
      background-color: #0a96f3;
    }
    span:nth-child(2) {
      font-size: 18px;
      margin: 0 20px 0 26px;
    }
    .el-divider {
      flex: 1;
      margin: 0;
    }
  }
  .content {
    // padding: 0 60px;
    .contentTitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
      }
      p:nth-child(1) {
        font-size: 26px;
        margin-bottom: 30px;
        margin-top: 30px;
      }
      p:nth-child(2) {
        color: #666;
        text-indent: 2em;
        margin-bottom: 20px;
        width: 100%;
      }
    }
    .introduction {
      margin-bottom: 30px;
      .btn {
        width: 120px;
        height: 38px;
        background-color: #0099ff;
        text-align: center;
        line-height: 38px;
        color: #fff;
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .wresTime {
      margin-bottom: 30px;
    }

    .registrationTime {
      margin-bottom: 30px;
    }
    .company {
      .companyBox {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 20px;
        .companyItem {
          width: 184px;
          height: 120px;
          box-shadow: 0px 0px 3px #eee;
          margin-bottom: 14px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          img {
            width: 60px;
            height: 60px;
          }
          span {
            // width: 100%;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            margin: 4px 0;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding: 0 10px;
          }
        }
      }
    }
  }
}

.time-bt  {
  padding: 0  60px;
.time-btn {
  background-color: #0099ff;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 8px;
  margin: 10px 0;
  border-radius: 3px;
  .left {
    display: flex;
    align-items: center;
    .leftRegisTime {
      margin-right: 10px;
    }
    // .leftWreTime{
    //   // display: flex;
    // }
  }
  .right {
  }
}
}

.math-info {
  height: 350px;
  background: url("~@/assets/jobFairDetails/bgImg.png")  center  center  no-repeat;
  box-sizing: border-box;

  .grid-content {
    text-align: center;
    margin: 45px 0;
    .span {
      color: #2b79ff;
      font-size: 36px;
    }
    .solid {
      width: 10px;
      height: 2px;
      margin: 6px auto;
      background-color: #2b79ff;
    }
  }
  .math-bottom {
    color: #666666;
    margin-left: 20px;
    font-size: 14px;
  }
}
.jobFairWork {
  margin-bottom: 100px;
  .work-content {
    display: flex;
    // justify-content: space-around;
    flex-wrap: wrap;
    .companyWork {
      width: 270px;
      margin-bottom: 20px;
      margin-right: 20px;
      margin: 0 11px 20px 12px;
      border: 1px solid #e0e0e0;
      .head {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
       opacity: 1;
        background: linear-gradient(90deg, rgba(227, 244, 255, 1) 0%, rgba(252, 246, 232, 0.07) 100%);
        // background-image: -webkit-gradient(
        //   linear,
        //   left 0,
        //   right 0,
        //   from(#e3f4ff),
        //   to(#fff)
        // );
        .head-img-box {
          width: 48px;
          height: 48px;
          margin-right: 12px;
          .head-img {
            width: 48px;
            height: 48px;
            margin-right: 12px;
          }
          .fLogo {
            width: 48px;
            height: 48px;
            text-align: center;
            line-height: 48px;
            border: 2px solid #ccc;
            background-color: #f4f4f4;

            span {
              font-size: 14px;
              color: #ccc;
              font-weight: bolder;
            }
          }
        }
        .head-right {
          .work-name {
            width: 195px;
            margin-bottom: 8px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .work-info {
            width: 190px;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .nav {
        box-sizing: border-box;
        margin-top: 30px;
        padding: 0 15px;
        height: 210px;
        .nav-info {
          .nav-list {
            height: 60px;
            margin-bottom: 8px;
            border-bottom: 1px dashed #e0e0e0;
            box-sizing: border-box;
            .left {
              display: flex;
              justify-content: space-between;
              .left-text {
                font-size: 16px;
                font-weight: bold;
              }
              .right-text {
                color: #ff8d19;
              }
            }
            .right {
              font-size: 14px;
              margin: 8px 0 12px 0;
              color: #999999;
            }
          }
        }
        .more {
          text-align: right;
          font-size: 12px;
          color: #999999;
        }
      }
      .btn {
        text-align: center;
        .square-btn {
          width: 205px;
          margin: 15px 0;
          height: 32px;
          padding-bottom: 21px;
        }
      }
    }
  }
}
.business-list {
  display: flex;
  flex-wrap: wrap;

  .business {
    width: 370px;
    margin-right: 17px;
    margin-bottom: 15px;
    border-radius: 3px;
    border: 1px solid #f4f4f4f4;
    background-color: #fff;
    box-sizing: border-box;
    // padding: 10px;

    .head {
      display: flex;
      padding: 10px;

      .img {
        width: 80px;
        height: 80px;
      }

      .right-info {
        flex: 1;

        .res-title {
          margin-bottom: 25px;
          display: flex;
          justify-content: space-between;

          .res-name,
          .pay {
            font-size: 18px;
            font-weight: 500;
          }

          .pay {
            color: #ff8d19;
          }

          .address {
            margin: 0 0 auto auto;
            color: #1592ea;
            text-align: right;
            font-size: 14px;

            .address-name {
              margin-left: 5px;

              .dian {
                height: 10px;
                font-size: 20px;
                margin: 0 5px;
              }
            }
          }
        }

        .title-info {
          color: #666;
          margin-bottom: 10px;
          font-size: 14px;
        }
      }
    }

    .label-list {
      display: flex;
      // background-color: #fafafafa;
      background: linear-gradient(90deg, rgba(230, 245, 254, 1) 0%, rgba(252, 246, 232, 0) 100%);
      font-size: 14px;
      padding: 10px 5px;

      .img {
        width: 26px;
        height: 26px;
        border-radius: 60px;
      }

      .cLogo {
        width: 26px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        border: 2px solid #ccc;
        background-color: #f4f4f4;

        span {
          font-size: 14px;
          color: #ccc;
          font-weight: bolder;
        }
      }

      .labe-info {
        color: #333;
        font-size: 18px;
        font-weight: 550;
        margin-left: 20px;
        align-items: center;
      }
    }
  }
}
::v-deep .el-tabs__item.is-top:last-child {
  padding-right: 20px;
}
::v-deep .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item.is-active {
  background-color: #fff;
}
::v-deep .el-tabs__content {
  background-color: #fff;
  box-sizing: border-box;
  padding: 25px 10px;
}
::v-deep .el-pagination {
  text-align: center;
}
</style>
